import {Progress} from "antd";
import {useEffect, useRef, useState} from "react";

type ProgressTimerProps = {
  startTime: number,
  skip: Function,
  expiryTimestamp: Date,
  hold: boolean,
  curr: number
}

const MAX_QUESTION_TIME = 20000
const RED_COLOR = "#FF0000"
const YELLOW_COLOR = "#ffff00"
const GREEN_COLOR = "#C3FFE6"

export function useStateAndRef(initial: any) {
  const [value, setValue] = useState(initial);
  const valueRef = useRef(value);
  valueRef.current = value;
  return [value, setValue, valueRef];
}

const ProgressTimer = (
  {
    startTime,
    skip,
    expiryTimestamp,
    hold,
    curr
  }: ProgressTimerProps
) => {
  const [timer, setTimer] = useState(MAX_QUESTION_TIME)
  const [perc, setPerc] = useState(0)
  const [count, setCount, countRef] = useStateAndRef(curr);
  const [qids, setQids] = useState<number[]>([])

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    setCount(curr)
  }, [curr])

  useEffect(() => {
    //console.log("Count", count)
    //prog(MAX_QUESTION_TIME, MAX_QUESTION_TIME, count)
  }, [count])

  function end(q: number) {
    console.timeEnd("s")
    skip(q)
  }
  function init() {
    //console.log('init')
    console.time("s")
    prog(MAX_QUESTION_TIME, MAX_QUESTION_TIME, curr)
  }

  const DIFF = 10
  function prog(timeLeft: number, timeTotal: number, question: number) {
    let c = countRef.current
    if(c === -1) return;
    if(c !== question) return;
    if(timeLeft >= DIFF) {
      let td = setTimeout(function () {
        if(timeLeft <= DIFF) {
          //console.log("ther there", c, question, timeLeft)
          end(question)
          return
        }
        prog(timeLeft-DIFF, timeTotal, question)
        setPerc(100 * (timeLeft) / MAX_QUESTION_TIME)
      }, DIFF)
    }
  }

  function strokeColors() {
    let c = RED_COLOR
    if(perc > 30) c = YELLOW_COLOR
    if(perc > 50) c = GREEN_COLOR
    return c
  }

  return(
    <div>
      <Progress
        percent={perc}
        showInfo={false}
        key={perc}
        strokeColor={strokeColors()}
      />
    </div>
  )
}

export default ProgressTimer