import communityDetails from "../../Common/Models/CommunityDetails";
import {Button, Divider, Form, message} from "antd";
import {useEffect, useState} from "react";

type ScoreCardProps = {
  status: 0 | 1 | 2 | 3,
  brandDetails: communityDetails | null,
  score: number,
  max: number,
  tryAgain: Function,
  stop: Function,
  intervalId: number | null
}

const QUALIFYING_PERCENTAGE = 0

const ScoreCard = (
  {
    status,
    brandDetails,
    score,
    max,
    tryAgain,
    stop,
    intervalId
  }: ScoreCardProps
) => {
  const [copied, setCopied] = useState(false)
  const [iForm] = Form.useForm()
  const [load,setLoad] = useState(false)
  let qual = QUALIFYING_PERCENTAGE
  const CODE = "HJ27MK"

  const formSubmit = () => {
    message.warn("Campaign is in Draft Mode.\n We will be live in 2-3 days.", )
  }

  useEffect(() => {
    console.log(intervalId)
  }, [intervalId])

  if(status !== 3 || !brandDetails) return null

  const copyCode = () => {
    window.navigator.clipboard.writeText(CODE)
    setCopied(true)
    message.success("Coupon Code Copied")
  }

  let p = score*100 / max;

  return (
    <div className={"ib-card-parent"}>
      <div className={"card ib-community-card switched"}>
        <div className={"card-content ib-card-content has-text-centered"}>
          <h4 className={"title is-size-3 is-size-5-mobile pt-4"}>
            Share this Screenshot as your IG Story
          </h4>
          <div className={"content"}>
            <b>Don't forget to mention @theearthrhythm</b>
            .5 lucky winners will get their favourite product.
            Just drop your details below
            <br /><br />
            <Form
              className={"has-text-left"}
              form={iForm}
              onFinish={() => formSubmit()}
            >
              <Form.Item
                noStyle
              >
                <Form.Item
                  name={"ib-community-email"}
                  rules={[{
                    required: true, type: 'email'
                  }]}
                  noStyle
                >
                </Form.Item>
              </Form.Item>
              <div
                className={"ib-form-email-comp"}
              >
                <i
                  className="fa fa-ig-ib fa-2x fa-envelope"
                  aria-hidden="true"
                />
                <input
                  name={"email"}
                  type={"email"}
                  className={"ib-input ib-instagram-email-input"}
                  placeholder={"Enter Email Here"}
                  defaultValue={iForm.getFieldValue("ib-community-email")}
                  onChange={e => iForm.setFieldsValue({"ib-community-email": e.target.value})}
                  required
                />
              </div>
              <Form.Item
                noStyle
              >
                <Form.Item
                  name={"ib-community-instagram"}
                  noStyle
                >
                </Form.Item>
                <div
                  className={"ib-form-instagram-input-comp"}
                >
                  <i
                    className="fa fa-ig-ib fa-2x fa-instagram"
                    aria-hidden="true"
                  />
                  <input
                    type="text"
                    id="ib-instagram-username-input"
                    className="ib-input ib-instagram-username-input"
                    placeholder={"Your Instagram Username"}
                    onChange={e => iForm.setFieldsValue({"ib-community-instagram": e.target.value})}
                    defaultValue={iForm.getFieldValue("ib-community-instagram")}
                  />
                </div>
              </Form.Item>
              <Form.Item
                className={"has-text-centered"}
                shouldUpdate
              >
                {() => {
                  return(
                    <button
                      disabled={load}
                      className={"button is-large is-rounded px-6 is-danger has-text-weight-bold"}
                      style={{
                        backgroundColor: "#111A22"
                      }}
                    >
                      Submit
                    </button>
                  )
                }}
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )

  if(p < qual) {
    return (
      <div className={"ib-card-parent"}>
        <div className={"card ib-community-card switched"}>
          <div className={"card-content ib-card-content has-text-centered"}>
            <h4 className={"title is-size-4 is-uppercase"}>
              results
            </h4>
            <div className={"p-6 is-size-1 has-text-weight-bold"}>
              TRY AGAIN!
            </div>
            <div className={"content has-text-centered pt-5 mb-5 "}>
              <p className={"has-text-weight-bold"}>
                Enjoyed the quiz?
              </p>
              Share it on Instagram and get 20% off on your next order!
            </div>
          </div>
        </div>
      </div>
    )
  }

  return(
    <div className={"ib-card-parent"}>
      <div className={"card ib-community-card switched"}>
        <div className={"card-content ib-card-content has-text-centered"}>
          <h4 className={"title is-size-4 is-uppercase"}>
            results
          </h4>
          <div className={"p-6 is-size-1 has-text-weight-bold"}>
            {score} / {max}
          </div>
          <div className={"content has-text-centered pt-5 mb-5 "}>
            <p className={"has-text-weight-bold"}>
              Well Played!
            </p>
            Here is a code for 20% off on your next order for playing!
          </div>

          <div>
            <p
              className={`button ${copied ? "copied" : ""} ib-reward-box has-text-weight-bold title is-size-4-tablet is-size-5`}
              onClick={() => copyCode()}
              title={"Click to Copy Code"}
            >
              {CODE}
              <i
                className="fa fa-clipboard pl-6"
                aria-hidden="true"
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ScoreCard