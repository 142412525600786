import {useEffect, useState} from "react";
import {useParams, useSearchParams} from "react-router-dom";
import {Button, Divider, Form, message, Result, Spin} from "antd";
import CommunityDetails from "../../Common/Models/CommunityDetails";
import {BadNotif, SuccessNotif} from "../../Common/Utils/SendNotification";
import isMobile from "is-mobile";
import {capitalize, validateEmail} from "../BrandPage/BrandPage";
import Loader from "../../components/Loader";
import SEO from "../../components/SEO";
import something from "../../Common/Images/something.png";
import FAQs from "../BrandApplication/FAQs";
import GetTriviaDetails from "../../Common/ApiCall/GetTriviaDetails";
import GetTriviaPlayEligible from "../../Common/ApiCall/GetTriviaPlayEligible";
import ScoreCard from "./ScoreCard";
import PicturesGalleria from "../../components/PicturesGalleria";
import TriviaGamePlayed from "../../Common/ApiCall/TriviaGamePlayed";
import {useStateAndRef} from "../QuizHome/ProgressTimer";
import GetGameDetails from "../../Common/ApiCall/GetGameDetails";

/*
status
0 - form
1 - quiz
2 - retry screen
3 - thanks for playing (won)
 */

const DEFAULT_IMAGE = "https://i.ibb.co/KjfbQmt/sullen-trivia.gif"

const ScreenshotComponent = () => {
  const [bName, setBname] = useState<string | number | null>(null)
  const [it, setIt] = useState(true)
  const [load, setLoad] = useState(false)
  const p = useParams()
  const [iForm] = Form.useForm()
  const [done, setDone] = useState<0 | 1 | 2 | 3>(0)
  const [searchP] = useSearchParams()
  const [brandDetails, setBrandDetails] = useState<CommunityDetails | null>(null)
  const [score, setScore] = useState(0)
  const [gameId, setGameId] = useState<null | number>(null)

  const [listOfImages, setListOfImages] = useState<any[]>([])
  const [activeImage, setActiveImage, activeImageRef] = useStateAndRef(0)
  const [intervalId, setIntervalId ] = useState<any>(null)

  const importAllImages = (r: any) => {
    return r.keys().map(r);
  }

  useEffect(() => {
    if(p && p.brand) setBname(p.brand)
    startImageSwitches()
    return(
      () => {
        clearInterval(intervalId)
      }
    )
  }, [])

  const switchImage = (images: any[]) => {
    let l = images.length
    let act = activeImageRef.current
    if(l > 0) {
      setActiveImage((act+1) % l)
    }
  }

  const startImageSwitches = () => {
    //@ts-ignore
    let allImages = importAllImages(require.context('../../Common/Images/er-images/', false, /\.(png|jpe?g|svg)$/))
    setListOfImages(allImages)
    let len = allImages.length
    if(len > 0) {
      setActiveImage((activeImage+1) % len)
    }
    let i = setInterval(() => {
      switchImage(allImages)
    }, 400)
    setIntervalId(i)
  }

  useEffect(() => {
    getBrandDetails()
  }, [bName])

  const stopFirst = () => {
    clearInterval(intervalId)
    setIntervalId(null)
    setDone(3)
  }

  const tryAgain = () => {
    startImageSwitches()
    setDone(0)
  }

  async function getBrandDetails() {
    if(bName) {
      const res = await GetGameDetails(bName)
      setIt(false)
      if(res.success) {
        let data = res.data
        setBrandDetails({...data})
        let d = document.getElementsByClassName("ib-bg")[0];
        //@ts-ignore
        d.style.backgroundColor = res.data.theme_bg
      } else {
        BadNotif(res)
      }
    }
  }

  async function formSubmit() {
    let a = iForm.getFieldsValue()
    let e = true
    let b = {
      email: a["ib-community-email"],
      page_id: bName,
      username: a["ib-community-instagram"],
      eligible: e ? "yes" : "no",
      mobile: isMobile() ? "yes" : "no"
    }
    if(!validateEmail(b.email)) {
      message.warn("Invalid Email.");return;
    }
    setLoad(true)
    const res = await GetTriviaPlayEligible(b)
    setLoad(false)
    if(res.success) {
      setGameId(res.data.game_id)
      setDone(1)
    } else {
      BadNotif(res)
    }
  }

  const played = async (finalScore: number) => {
    setLoad(true)
    let a = iForm.getFieldsValue()
    console.log(a)
    let e = true
    let b = {
      email: a["ib-community-email"],
      page_id: bName,
      username: a["ib-community-instagram"],
      eligible: e ? "yes" : "no",
      mobile: isMobile() ? "yes" : "no",
      score: finalScore,
      game_id: gameId
    }
    const r = await TriviaGamePlayed(b)
    if(r.success) {
    } else {
      BadNotif(r)
    }
    setLoad(false)
  }

  function complete(score: number) {
    setScore(score)
    played(score)
      .then(() => {
        setDone(3)
      })
  }

  function hexfix(str: string) {
    str = str.substring(1)
    if (str.length === 3) {
      str = str.split('').map(function (hex) {
        return hex + hex;
      }).join('');
    }
    return str
  }

  const getTextColor = (color: string) => {
    color = hexfix(color)
    let c = "#fff"
    //if(l && l < 20) c = "#fff"
    return c
  }

  if(it || !brandDetails) {
    if(it) {
      return (
        <section className="hero is-fullheight theme-bg">
          <div className="hero-body has-text-centered">
            <div className="container">
              <Loader />
            </div>
          </div>
        </section>
      )
    } else {
      return (
        <section className="hero is-fullheight theme-bg">
          <div className="hero-body has-text-centered">
            <div className="container">
              <Result
                status="500"
                title="500"
                subTitle="Invalid URL."
              />
            </div>
          </div>
        </section>
      )
    }
  }

  if(load) {
    return(
      <div className={"hero isheightmax theme-bg"}>
        <SEO
          title={capitalize(brandDetails?.brand_name + " | Influencerbit Community")}
          img={brandDetails.brand_logo ?? ""}
        />
        <div className={"hero-body"}>
          <div className={"container"}>
            <div className={"columns is-mobile is-multiline is-vcentered"}>
              <div className={"column is-6-tablet is-12-mobile"}>
                <div className={"ib-card-parent"}>
                  <div
                    className={"card ib-community-card"}
                    style={{
                      minHeight: "50vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <div
                      className={"card-content ib-card-content has-text-centered"}
                      style={{
                        backgroundColor: brandDetails.bg_color,
                      }}
                    >
                      <Spin />
                    </div>
                  </div>
                </div>
              </div>
              <div className={"column is-6-mobile-only has-text-centered"}>
                <img
                  src={something}
                  style={{maxWidth: "80%"}}
                  className={"mb-3 is-pulled-left"}
                />
                <img
                  className={"ib-community-image"}
                  src={brandDetails.images.length>0 ? brandDetails.images[0] : "https://uploads.dovetale.net/processed-landing-page-images/ac8da5449ec2d7541d3925da3054749d.png"}
                />
              </div>
              <div className={"column is-6-mobile-only has-text-centered"}>
                <img
                  src={brandDetails.images.length>1 ? brandDetails.images[1] : "https://uploads.dovetale.net/processed-landing-page-images/ac8da5449ec2d7541d3925da3054749d.png"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return(
    <div className={"hero theme-bg"}>
      <SEO
        title={capitalize(brandDetails?.brand_name + " | Influencerbit Community")}
        img={brandDetails.brand_logo ?? ""}
      />
      <div className={"hero-body"}>
        <div className={"container"}>
          <div className={"columns is-mobile is-multiline is-centered is-vcentered"}>
            <div
              className={"column is-5-desktop is-6-tablet has-text-centered"}
              style={{
                position: 'static'
              }}
            >
              <div
                className={"card"}
                style={{
                  mixBlendMode: "normal",
                  boxShadow: "0px 12px 20px 8px rgba(0, 0, 0, 0.14)",
                  borderRadius: "32px",
                  maxWidth: "500px"
                }}
              >
                <div
                  className={"card-content"}
                >
                  <h5
                    className={"title px-3 has-text-centered is-size-5-tablet is-size-6-mobile has-text-black"}
                  >
                    My Favourite EarthRhythm Product ✨
                  </h5>
                </div>
                <div
                  className={"card mx-4"}
                  style={{
                    mixBlendMode: "normal",
                    border: "12px solid #F4F6F7",
                    boxShadow: "0px 12px 16px rgba(41, 41, 41, 0.25)",
                    borderRadius: "30px",
                    position: 'relative',
                    top: isMobile() ? "10px" : "20px"
                  }}
                >
                  <div className={"card-content"}>
                    {listOfImages.map((i, index) => {
                      return(
                        <div>
                          <img
                            key={index}
                            hidden={index!==activeImage}
                            src={i}
                            alt={String(index)}
                          />
                          {
                            (!intervalId) && (
                              <i
                                className="fa fa-2x fa-check"
                                aria-hidden="true"
                                style={{
                                  position: "absolute",
                                  right: -15,
                                  top: -15,
                                  width: "48px",
                                  height: "48px",
                                  backgroundColor: "#6C63FF",
                                  color: "#fff",
                                  borderRadius: "70px",
                                  paddingLeft: "7px",
                                  paddingTop: "7px"
                                }}
                              />
                            )
                          }
                        </div>
                      )
                    })}
                    {(done === 3 || isMobile()) && (
                      !intervalId ? (
                        <button
                          onClick={() => tryAgain()}
                          className={"button is-rounded is-large is-black px-6 has-text-weight-bold"}
                        >
                          Naah! Try Again
                        </button>
                      ) : (
                        <>
                          <button
                            className={"button is-rounded is-large is-black px-6 has-text-weight-bold"}
                            onClick={() => stopFirst()}
                          >
                            STOP
                          </button>
                        </>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={"column is-7-desktop is-6-tablet is-12-mobile"}>
              <ScoreCard
                status={done}
                brandDetails={brandDetails}
                score={score}
                max={0}
                stop={stopFirst}
                tryAgain={tryAgain}
                intervalId={intervalId}
              />
              <div className={"ib-card-parent"}>
                <div
                  className={"card ib-community-card"}
                  hidden={done !== 0 || isMobile()}
                  style={{
                    boxShadow: "0px 12px 16px rgba(41, 41, 41, 0.25)",
                  }}
                >
                  <div
                    className={"card-content ib-card-content py-6 has-text-centered"}
                    style={{
                      backgroundColor: brandDetails.bg_color,
                      mixBlendMode: "normal",
                      borderRadius: "60px"
                    }}
                  >
                    <h4
                      className={"title px-2 is-size-4-desktop is-size-5"}
                    >
                      What's your favourite Earthrhythm's Product? 🏆
                    </h4>
                    <h5
                      className={"subtitle is-size-5-desktop pt-4"}
                    >
                      Stand a chance to get a discount!
                    </h5>
                    <Divider />
                    <Button
                      className={"button is-rounded is-large is-black px-6 has-text-weight-bold"}
                      onClick={() => stopFirst()}
                    >
                      STOP
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PicturesGalleria
          pictures={brandDetails.pictures}
          hide={true}
        />
        {brandDetails.faqs.show && (
          <div className={"my-6"}>
            <FAQs
              campaign_id={brandDetails.id}
              faqItems={brandDetails.faqs.items}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default ScreenshotComponent